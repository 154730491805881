<template>
  <div class="moreinfo">
    <v-btn icon @click="back">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <iframe
      src="https://www.campnageela.org/"
      style="position:fixed; top:4%; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden;"
    >Your browser doesn't support iframes please visit https://www.campnageela.org/</iframe>
  </div>
</template>
<script>
export default {
  name: "Home",
  components: {},
  methods: {
    async back() {
      this.$router.push({ name: "Home" });
    }
  }
};
</script>
<style scoped>

</style>
